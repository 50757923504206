import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Routes from "./Routes";
import "./App.css";
import Footer from "./containers/Footer";

function App() {
  return (
    <div className="App container py-3">
      <Navbar collapseOnSelect bg="light" expand="md" className="mb-3 px-3">
        <Navbar.Brand href="/" className="fw-bold text-muted">streetsmarts.io</Navbar.Brand>
        <Navbar.Toggle />
      </Navbar>
      <Routes />
      <Footer />
    </div>
  );
} 

export default App;