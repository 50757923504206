import React from "react"

const currentYear = new Date().getFullYear();

const Footer = () => <footer className="page-footer font-small blue pt-4">

    <div className="footer-copyright text-center py-3">© {currentYear} Street Smarts Analytics, LLC
    </div>

</footer>

export default Footer